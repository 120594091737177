import {Controller} from '@hotwired/stimulus';
import Cookies from 'js-cookie';
import {notify} from 'src/utils/browser_notification';
import {push} from 'src/Push';
import Modal from 'src/ui/loaders/ModalLoader';
import {hasTouchSupport} from 'src/utils/env';
import PreserveScroll from './global/PreserveScroll';
import Toast from 'src/ui/global/Toast';
import {renderStreamMessage, visit} from '@hotwired/turbo';

export default class extends Controller {
    static targets = [
        'topBarFootprint',
        'topBarContainer',
        'globalAdminBar',
        'notificationsFlag',
        'notificationsPopover',
    ];

    static values = {
        unseenNotificationsCount: Number,
        sessionId: String,
    };

    documentTitle = null;

    connect() {
        this.element.globalController = this;

        if (this.hasSessionIdValue && !!this.sessionIdValue) {
            // Receive Notifications
            push.on('message', (data) => this._handlePushMessage(data));

            this._checkSession();
        }

        this._handleGlobalClasses();

        this.preserveScroll = new PreserveScroll();
        this.preserveScroll.bind();
    }

    _handleToastsInPushMessages(data) {
        if (data?.toast) {
            const toastData = data.toast;
            if (toastData?.hide_on && this._isUserOnPath(toastData.hide_on)) {
                return;
            }
            console.log(toastData);
            Toast.show(toastData);
        }
    }

    _handleTurboInPushMessages(data) {
        const turboActions = data?.turbo;
        if (!turboActions) {
            return;
        }
        turboActions.forEach((turboAction) => {
            if (
                turboAction?.run_on &&
                !this._isUserOnPath(turboAction.run_on)
            ) {
                return;
            }
            switch (turboAction?.type) {
                case 'frame': {
                    const frameId = turboAction?.frame_id;
                    if (!frameId) {
                        break;
                    }
                    const frame = document.getElementById(frameId);
                    if (frame) {
                        if (turboAction?.url) {
                            frame.src = turboAction.url;
                        } else {
                            frame.reload();
                        }
                    }
                    break;
                }
                case 'stream': {
                    if (turboAction?.url) {
                        fetch(turboAction.url, {
                            method: 'GET',
                            headers: {
                                Accept: 'text/vnd.turbo-stream.html',
                            },
                        })
                            .then((response) => response.text())
                            .then((html) => {
                                renderStreamMessage(html);
                            });
                    }
                    break;
                }
                case 'visit': {
                    visit(turboAction.url);
                    break;
                }
            }
        });
    }

    _handlePushMessage(data) {
        this._handleToastsInPushMessages(data);
        this._handleTurboInPushMessages(data);

        /**
         * Update notification counters
         */
        switch (data?.type) {
            case 'notification': {
                this._incrementUnseenNotificationsCount();
                notify(`Aiir: ${data.name}`, data.text, data.id);
                break;
            }
            case 'clear_notifications': {
                this._resetUnseenNotificationsCount();
                break;
            }
            default: {
                console.log(data);
            }
        }
    }

    _incrementUnseenNotificationsCount() {
        this.unseenNotificationsCountValue =
            this.unseenNotificationsCountValue + 1;
    }

    _resetUnseenNotificationsCount() {
        this.unseenNotificationsCountValue = 0;
    }

    unseenNotificationsCountValueChanged(num) {
        if (num !== 0) {
            this.notificationsPopoverTarget.dataset['popoverLoadedValue'] =
                'false';
        }

        this.notificationsFlagTargets.forEach((el) => {
            el.innerText = num;
            el.classList.toggle('tone-u-hidden', num === 0);
        });

        if (this.documentTitle !== null) {
            // Only update the title here when this is a pushed change,
            //  i.e. excluding the initial page load
            // We need documentTitle to avoid doubling up the (number),
            //  and that's only set once page-controller connects
            document.title = `${num > 0 ? `(${num})` : ''} ${
                this.documentTitle
            }`;
        }
    }

    // Called by page-controller->connect()
    handleDocumentTitleChange() {
        if (document.title === this.documentTitle) {
            return;
        }

        const title = document.title;
        this.documentTitle = title;

        const notifs = this.unseenNotificationsCountValue;
        if (notifs > 0) {
            document.title = `(${notifs}) ${title}`;
        }
    }

    hideGlobalAdminBar() {
        document.body.classList.remove('showing-admin-bar');

        this.topBarFootprintTarget.style.marginTop = 0;
        this.topBarContainerTarget.style.marginTop = 0;

        fetch('/home/toggle-admin-bar', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({showAdminBar: '0'}),
        });
    }

    async openSearch(e) {
        // If triggered by keyboard shortcut, ignore when an input has focus
        if (
            e.type === 'keydown' &&
            (/textarea|input|select/i.test(e.target.nodeName) ||
                e.target?.type === 'text' ||
                e.target?.contentEditable === 'true')
        ) {
            return;
        }

        const {url} = e.params;
        if (this.searchModal) {
            this.searchModal.show();
            document.getElementById('global_search_input')?.focus();
            e.preventDefault();
        } else {
            this.searchModal = await Modal.open({
                title: 'Search',
                url,
                persist: true,
                boxedClass: 'modal-inst--search',
                onHideFocusEl: document.getElementById('top_search_btn'),
            });
        }
    }

    /**
     * Check status of user's session
     */
    sessionCheckInt = null;

    _checkSession() {
        this.sessionCheckInt = setInterval(
            () => {
                /**
                 * Checking cookie only, which should match the session on the server
                 */

                const sessCookie = Cookies.get('awSession');

                if (!sessCookie) {
                    clearInterval(this.sessionCheckInt);

                    Modal.open({
                        title: 'Your Aiir session',
                        url: '/no_session_info.html',
                        showCloseBtn: false,
                        clickOverlayToHide: false,
                    });
                }
            },
            1000 * 60, // 1 min
        );
    }

    _handleGlobalClasses() {
        this.element.classList.add('js');
        this.element.classList.add(hasTouchSupport() ? 'touch' : 'no-touch');

        const isElectron = navigator.userAgent
            .toLowerCase()
            .includes(' electron/');
        if (isElectron) {
            this.element.classList.add('is-electron');
        }
    }

    _isUserOnPath(targetPath) {
        return window.location.pathname === targetPath;
    }
}
